import React from 'react';
import {
    VictoryChart,
    VictoryScatter,
    VictoryLine,
    VictoryTheme,
    VictoryLegend,
    VictoryTooltip,
    VictoryAxis,
    VictoryLabel
} from "victory";

// Utility to format numbers on the axis
const formatAxisNumber = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9) return +(n / 1e9).toFixed(1) + "B";
};

export const ViivaGraafi = ({ title, barsLegend, linesLegend, data, lineSeries1, lineSeries2, timeScale, width, height }) => {
    const [lines1, setLines1] = React.useState([]);
    const [lines2, setLines2] = React.useState([]);
    const [domain, setDomain] = React.useState([]);
    const [chartWidth, setChartWidth] = React.useState(width);
    const [chartHeight, setChartHeight] = React.useState(height);
    const [maxLine1, setMaxLine1] = React.useState(0);
    const [maxLine2, setMaxLine2] = React.useState(0);

    React.useEffect(() => {
        if (data) {
            const ds1 = data[lineSeries1] || [];
            const ds2 = data[lineSeries2] || [];
            const ds3 = ds1.map(d => d.x);  // Assuming 'x' is the year or time data
            setLines1(ds1);
            setLines2(ds2);
            setDomain(ds3);
        }
    }, [data, lineSeries1, lineSeries2]);

    React.useEffect(() => {
        // Calculate the maximum for Line 1, fallback to zero if empty
        const max1 = lines1.length > 0 ? Math.max(...lines1.map(o => o.y)) : 0;
        setMaxLine1(max1);
    }, [lines1]);

    React.useEffect(() => {
        // Calculate the maximum for Line 2, fallback to zero if empty
        const max2 = lines2.length > 0 ? Math.max(...lines2.map(o => o.y)) : 0;
        setMaxLine2(max2);
    }, [lines2]);

    return (
        <div>
            <VictoryChart
                animate={false}
                padding={{ top: 30, bottom: 50, left: 70, right: 70 }}
                theme={VictoryTheme.material}
                domainPadding={20}
                width={chartWidth}
                height={chartHeight}
            >
                {/* Legend for the lines */}
                <VictoryLegend x={parseInt(chartWidth / 4)} y={10}
                    orientation="horizontal"
                    colorScale={["#800000", "#000"]}
                    data={[
                        { name: "Skenaario 1", symbol: { fill: "#800000" } },
                        { name: "Skenaario 2", symbol: { fill: "#000" } }
                    ]}
                />

                {/* X-Axis */}
                <VictoryAxis
                    tickValues={domain}
                    tickFormat={(t) => `${t}`}  // Assuming 't' is the year or numeric value
                    fixLabelOverlap
                />

                {/* Y-Axis for Line 1 */}
                <VictoryAxis
                    dependentAxis
                    orientation="left"
                    label={barsLegend}
                    axisLabelComponent={<VictoryLabel dy={-50} />}
                    tickFormat={(t) => formatAxisNumber(t * maxLine1)}
                />

                {/* Y-Axis for Line 2 */}
                <VictoryAxis
                    dependentAxis
                    orientation="right"
                    label={linesLegend}
                    axisLabelComponent={<VictoryLabel dy={50} />}
                    tickFormat={(t) => formatAxisNumber(t * maxLine2)}
                />

                {/* Line Chart for Skenaario 1 */}
                <VictoryLine
                    data={lines1}
                    style={{ data: { stroke: "#800000", strokeWidth: 2 } }}
                    y={(datum) => maxLine1 > 0 ? datum.y / maxLine1 : datum.y}
                />

                {/* Line Chart for Skenaario 2 */}
                <VictoryLine
                    data={lines2}
                    style={{ data: { stroke: "#000", strokeWidth: 2, strokeDasharray: 5 } }}
                    y={(datum) => maxLine2 > 0 ? datum.y / maxLine2 : datum.y}
                />

                {/* Scatter Points for Skenaario 1 */}
                <VictoryScatter
                    style={{ data: { fill: "#800000" } }}
                    size={5}
                    data={lines1}
                    labels={({ datum }) => `Vuosi ${datum.x}\nPäästöt: ${datum.y}`}
                    labelComponent={<VictoryTooltip />}
                    y={(datum) => maxLine1 > 0 ? datum.y / maxLine1 : datum.y}
                />

                {/* Scatter Points for Skenaario 2 */}
                <VictoryScatter
                    style={{ data: { fill: "#000" } }}
                    size={5}
                    data={lines2}
                    labels={({ datum }) => `Vuosi ${datum.x}\nKulutus: ${datum.y}`}
                    labelComponent={<VictoryTooltip />}
                    y={(datum) => maxLine2 > 0 ? datum.y / maxLine2 : datum.y}
                />
            </VictoryChart>
        </div>
    );
};