import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { authFetch, authPost } from "../../authProvider";
import { LanguageContext } from '../LanguageContext';

/* Constants */
const API_PREFIX = 'api/ESRS/Aihe';

/* Main Component */
export class AiheE1 extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {

        this.setState({ loading: false });
    }


    render() {
        const { loading } = this.state;

        return (
            <div style={{ padding: 10 }}>
                <div style={{ overflowY: "scroll", height: "90vh" }}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Grid container>
                                <Grid xs={12}>
                                    <Box>
                                        ESRS E1
                                    </Box>
                                </Grid>
                        </Grid>
                    )}
                </div>
            </div>
        );
    }
}