import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { authFetch, authPost } from "../../authProvider";
import { LanguageContext } from '../LanguageContext';

/* Constants */
const API_PREFIX = 'api/ESRS/Aihe';

/* Main Component */
export class AiheG extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {

        this.setState({ loading: false });
    }


    render() {
        const { loading } = this.state;
        const { dictionary } = this.context;

        return (
            <div style={{ padding: 10 }}>
                <div style={{ overflowY: "scroll", height: "90vh" }}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Grid container>
                            <Grid xs={12}>
                                <h1>{"Liiketoimi ja hallinointi"}</h1>
                            </Grid>
                            <Grid xs={12}>
                                <Box>
                                    <TextField
                                        label="Liiketoimi ja hallinointi"
                                        placeholder="Kirjoita yleinen kuvaus liiketoiminnasta ja sen positiivisista ja negatiivisista vaikutuksista"
                                        multiline
                                        rows={10}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                            </Grid>
                            <Grid xs={12}>
                                <Box sx={{ display: "flex" }}>
                                    <Box sx={{ flexGrow: 1 }} />
                                        <Button variant="outlined" color="primary" style={{ margin: 4 }} onClick={() => null}>
                                        {dictionary.Cancel}
                                    </Button>
                                    <Button variant="contained" color="primary" style={{ color: "#fff", margin: 4 }} onClick={() => null}>
                                        {dictionary.Save}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </div>
            </div>
        );
    }
}