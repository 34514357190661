import *  as React from 'react';
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";

import { LanguageContext } from './LanguageContext';
import { LocalMenu } from './LocalMenu';
import { PowerBIContent } from "./PowerBIContent";
import { Organisaatio } from "./Inventory/Organisaatio";
import { Kustannuspaikka } from "./Inventory/Kustannuspaikka";
import { Projekti } from "./Inventory/Projekti";
import { ProjektiOrganisaatio } from "./Inventory/ProjektiOrganisaatio";
import { Tilitapahtuma } from "./DataIngestion/Tilitapahtuma";
import { Kulutus } from "./DataIngestion/Kulutus";
import { Tuotanto } from "./DataIngestion/Tuotanto";
import { PaastoRaportti } from "./DataIngestion/PaastoRaportti";
import { Skenariointi } from "./Simulation/Skenariointi";
import { SkenaarioVertailu } from "./Simulation/SkenaarioVertailu";
import { AiheG, AiheE1, AiheE2, AiheE3, AiheE4, AiheE5, AiheS3, AiheS4 } from "./ESRS";

export const ReportContent = (props) => {

    const context = React.useContext(LanguageContext);
    const { instance } = useMsal();
    const location = useLocation();
    const { item, parent } = location.state;
    const [selected, setSelected] = React.useState(item);
    const [selectedParent, setSelectedParent] = React.useState(parent);

    React.useEffect(() => {
        setSelected(item);
        setSelectedParent(parent);
    }, [item, parent]);

    const title = selectedParent?.lang[context.userLanguage]?.title;
    const items = selectedParent?.children.map(d => ({ id: d.id, name: d.lang[context.userLanguage]?.title, info: d.lang[context.userLanguage]?.updateInfo }));

    const handleSelectChange = (id) => {
        if (id) {
            if (props.onSelectionChange) {
                props.onSelectionChange(id);
            }

            setSelected(location.state?.parent?.children.filter(d => (d.id === id))[0]);
        }
    }

    const handleSearch = (text) => {
        alert(text);
    }

    return (
        <div style={{ display: "block", width: "100%" }}>
            <LocalMenu
                title={title}
                menuItems={(items.length > 0 ? items : null)}
                selectedId={selected?.id}
                onSelectChange={handleSelectChange}
                onSearchClicked={handleSearch}
                onlyTopLevel={(items.length === 1 && title === items[0].name)}
            />
            <div style={{ marginTop: 56 }}>
                {selected?.lang[context.userLanguage]?.reportId === 'Organisaatio' ? ( // Sivu: Inventory/Organisaatio
                    <Organisaatio pca={instance} onMessage={props.onMessage} onError={props.onError} />
                ) : selected?.lang[context.userLanguage]?.reportId === 'Kustannuspaikka' ? ( // Sivu: Inventory/Kustannuspaikka
                    <Kustannuspaikka pca={instance} onMessage={props.onMessage} onError={props.onError} />
                ) : selected?.lang[context.userLanguage]?.reportId === 'Projekti' ? ( // Sivu: Inventory/Projekti
                    <Projekti pca={instance} onMessage={props.onMessage} onError={props.onError} />
                ) : selected?.lang[context.userLanguage]?.reportId === 'ProjektiOrganisaatio' ? ( // Sivu: Inventory/ProjektiOrganisaatio
                    <ProjektiOrganisaatio pca={instance} onMessage={props.onMessage} onError={props.onError} />
                ) : selected?.lang[context.userLanguage]?.reportId === 'Kirjanpito' ? ( // Sivu: DataIngestion/Tilitapahtuma
                    <Tilitapahtuma pca={instance} onMessage={props.onMessage} onError={props.onError} />
                ) : selected?.lang[context.userLanguage]?.reportId === 'Kulutus' ? ( // Sivu: DataIngestion/Kulutus
                    <Kulutus pca={instance} onMessage={props.onMessage} onError={props.onError} />
                ) : selected?.lang[context.userLanguage]?.reportId === 'Tuotanto' ? ( // Sivu: DataIngestion/Tuotanto
                    <Tuotanto pca={instance} onMessage={props.onMessage} onError={props.onError} />
                ) : selected?.lang[context.userLanguage]?.reportId === 'Skenariointi' ? ( // Sivu: Simulation/Skenariointi
                    <Skenariointi pca={instance} onMessage={props.onMessage} onError={props.onError} />
                ) : selected?.lang[context.userLanguage]?.reportId === 'SkenaarioVertailu' ? ( // Sivu: Simulation/Skenariointi
                    <SkenaarioVertailu pca={instance} onMessage={props.onMessage} onError={props.onError} />
                ) : selected?.lang[context.userLanguage]?.reportId === 'PaastoRaportti' ? ( // Sivu: DataIngestion/Paastoraportti
                    <PaastoRaportti pca={instance} onMessage={props.onMessage} onError={props.onError} />
                ) : selected?.lang[context.userLanguage]?.reportId === 'AiheG' ? ( // Sivu: ESRS/AiheG
                    <AiheG pca={instance} onMessage={props.onMessage} onError={props.onError} />
                ) : selected?.lang[context.userLanguage]?.reportId === 'AiheE1' ? ( // Sivu: ESRS/AiheE1
                    <AiheE1 pca={instance} onMessage={props.onMessage} onError={props.onError} />
                ) : selected?.lang[context.userLanguage]?.reportId === 'AiheE2' ? ( // Sivu: ESRS/AiheE2
                    <AiheE2 pca={instance} onMessage={props.onMessage} onError={props.onError} />
                ) : selected?.lang[context.userLanguage]?.reportId === 'AiheE3' ? ( // Sivu: ESRS/AiheE3
                    <AiheE3 pca={instance} onMessage={props.onMessage} onError={props.onError} />
                ) : selected?.lang[context.userLanguage]?.reportId === 'AiheE4' ? ( // Sivu: ESRS/AiheE4
                    <AiheE4 pca={instance} onMessage={props.onMessage} onError={props.onError} />
                ) : selected?.lang[context.userLanguage]?.reportId === 'AiheE5' ? ( // Sivu: ESRS/AiheE5
                    <AiheE5 pca={instance} onMessage={props.onMessage} onError={props.onError} />
                ) : selected?.lang[context.userLanguage]?.reportId === 'AiheS3' ? ( // Sivu: ESRS/AiheS3
                    <AiheS3 pca={instance} onMessage={props.onMessage} onError={props.onError} />
                ) : selected?.lang[context.userLanguage]?.reportId === 'AiheS4' ? ( // Sivu: ESRS/AiheS4
                    <AiheS4 pca={instance} onMessage={props.onMessage} onError={props.onError} />

                ) : ( // Power BI raportit
                    <PowerBIContent
                        ReportId={selected?.lang[context.userLanguage]?.reportId}
                        DatasetId={selected?.lang[context.userLanguage]?.datasetId}
                        PageId={selected?.lang[context.userLanguage]?.pageId}
                        Filters={selected?.filters}
                        PageNavigation={selected?.pageNavigation}
                        Bookmarks={selected?.bookmarks}
                        DisplayOption={selected?.displayOption}
                    />
                )}
            </div>
        </div>
    );
}
