import React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import dayjs from 'dayjs';
import { withStyles } from "@mui/styles";

import { LanguageContext } from '../LanguageContext';
import LocalizedDatePicker from "../LocalizedDatePicker";

const defaultToolbarStyles = {
    iconButton: {},
};

class SkenariointiAdd extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            RiviAvain: null,
            SkenaarioNimi: '',
            SkenaarioKuvaus: '',
            Omistaja: '',
            Alku: dayjs(),
            Loppu: dayjs(),
            Asetukset: null,
            Jaettu: true,
            AsiakasAvain: null,
            asiakkaat: (Object.keys(props.lookupLists.asiakkaat).map((d) => ({ value: d, label: props.lookupLists.asiakkaat[d] })) || [])
        };
    }

    handleSave = () => {
        this.props.onAddNewRow({
            RiviAvain: 1,
            SkenaarioNimi: this.state.SkenaarioNimi,
            SkenaarioKuvaus: this.state.SkenaarioKuvaus,
            Omistaja: this.state.Omistaja,
            Alku: this.state.Alku.format("DD/MM/YYYY 00:00:00"),
            Loppu: this.state.Loppu.format("DD/MM/YYYY 23:59:59"),
            Asetukset: " ",
            Jaettu: this.state.Jaettu,
            AsiakasAvain: this.state.AsiakasAvain
        });

        this.setState({ open: false });
    };

    closeDialog = (list) => {
        var name = 'open' + list;
        this.setState({ [name]: false })
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    }

    handleListChange = (event, newValue, item) => {
        if (newValue != null) {
            this.setState({ [item]: newValue.value })
        }
        else {
            this.setState({ [item]: null })
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    handleChangeDate = (name, value) => {
        this.setState({ [name]: value });
    }

    render() {
        const { dictionary } = this.context;
        const { open, asiakkaat } = this.state;

        return (
            <React.Fragment>
                <Tooltip title={dictionary.Toolbar.NewRow}>
                    <IconButton onClick={this.handleClickOpen}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>

                <Dialog open={open} onClose={this.handleClose}>
                    <DialogTitle>{dictionary.Simulation.Skenaario.Title}</DialogTitle>
                    <DialogContent style={{ width: "500px" }}>
                        <Box style={{ display: 'flex', paddingTop: "10px" }}>
                            <Autocomplete
                                onChange={(event, newValue) => this.handleListChange(event, newValue, 'AsiakasAvain')}
                                options={asiakkaat}
                                style={{ width: 400 }}
                                renderInput={(params) => <TextField {...params} required={true} variant="standard" label={dictionary.Simulation.Skenaario.Columns[4]} />}
                            />
                        </Box>
                        <TextField
                            margin="dense"
                            id="SkenaarioNimi"
                            label={dictionary.Simulation.Skenaario.Columns[1]}
                            type="text"
                            fullWidth
                            value={this.state.SkenaarioNimi}
                            onChange={this.handleChange('SkenaarioNimi')}
                        />
                        <TextField
                            margin="dense"
                            id="SkenaarioKuvaus"
                            label={dictionary.Simulation.Skenaario.Columns[2]}
                            type="text"
                            fullWidth
                            value={this.state.SkenaarioKuvaus}
                            onChange={this.handleChange('SkenaarioKuvaus')}
                        />
                        <LocalizedDatePicker
                            label={dictionary.Simulation.Skenaario.Columns[5]}
                            value={this.state.Alku}
                            onChange={(newValue) => this.handleChangeDate('Alku', newValue)}
                        />
                        <LocalizedDatePicker
                            label={dictionary.Simulation.Skenaario.Columns[6]}
                            value={this.state.Loppu}
                            onChange={(newValue) => this.handleChangeDate('Loppu', newValue)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={this.handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "SkenariointiAdd" })(SkenariointiAdd);