import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { authFetch, authPost } from "../../authProvider";
import { LanguageContext } from '../LanguageContext';
import { ViivaGraafi } from "./ViivaGraafi";
import { TreeView, TreeItem } from '@mui/lab';

/* Constants */
const API_PREFIX = 'api/DataIngestion/PaastoRaportti';
const API_KUSTANNUSPAIKKA_PREFIX = 'api/Inventory/Kustannuspaikka';
const API_ORGANISAATIOT_PREFIX = 'api/Inventory/Organisaatio';
const API_PROJEKTIT_PREFIX = 'api/Inventory/Projekti';

/* Dummy Data */
const dummyData = [
    {
        id: 1,
        name: "Skenaario 1",
        paastot: [
            { x: 2023, y: 15667 },
            { x: 2024, y: 20000 },
            { x: 2025, y: 25000 },
            { x: 2026, y: 28000 }
        ],
        kulutus: [
            { x: 2023, y: 112 },
            { x: 2024, y: 140 },
            { x: 2025, y: 180 },
            { x: 2026, y: 190 }
        ]
    },
    {
        id: 2,
        name: "Skenaario 2",
        paastot: [
            { x: 2023, y: 1131 },
            { x: 2024, y: 1200 },
            { x: 2025, y: 1400 },
            { x: 2026, y: 1600 }
        ],
        kulutus: [
            { x: 2023, y: 234 },
            { x: 2024, y: 250 },
            { x: 2025, y: 260 },
            { x: 2026, y: 300 }
        ]
    }
];

const organisaatiotDummyData = [
    {
        riviAvain: 'org-1',
        nimi: 'Startecon Oy',
        children: [
            {
                riviAvain: 'org-1-1',
                nimi: 'Sales Department',
                children: [
                    { riviAvain: 'org-1-1-1', nimi: 'Team A' },
                    { riviAvain: 'org-1-1-2', nimi: 'Team B' }
                ]
            },
            {
                riviAvain: 'org-1-2',
                nimi: 'Research Department',
                children: [
                    { riviAvain: 'org-1-2-1', nimi: 'Team X' },
                    { riviAvain: 'org-1-2-2', nimi: 'Team Y' }
                ]
            }
        ]
    },
    {
        riviAvain: 'org-2',
        nimi: 'Innovatech Ltd',
        children: [
            {
                riviAvain: 'org-2-1',
                nimi: 'Marketing',
                children: [
                    { riviAvain: 'org-2-1-1', nimi: 'North Region' },
                    { riviAvain: 'org-2-1-2', nimi: 'South Region' }
                ]
            }
        ]
    }
];

const kustannuspaikkaDummyData = [
    {
        riviAvain: 'cost-1',
        nimi: 'Main Office',
        children: [
            {
                riviAvain: 'cost-1-1',
                nimi: 'Finance Department',
                children: [
                    { riviAvain: 'cost-1-1-1', nimi: 'Accounts Payable' },
                    { riviAvain: 'cost-1-1-2', nimi: 'Accounts Receivable' }
                ]
            },
            {
                riviAvain: 'cost-1-2',
                nimi: 'HR Department',
                children: [
                    { riviAvain: 'cost-1-2-1', nimi: 'Recruitment' },
                    { riviAvain: 'cost-1-2-2', nimi: 'Employee Relations' }
                ]
            }
        ]
    },
    {
        riviAvain: 'cost-2',
        nimi: 'Branch Office',
        children: [
            {
                riviAvain: 'cost-2-1',
                nimi: 'Logistics',
                children: [
                    { riviAvain: 'cost-2-1-1', nimi: 'Warehouse A' },
                    { riviAvain: 'cost-2-1-2', nimi: 'Warehouse B' }
                ]
            }
        ]
    }
];

const TreeViewComponent = ({ title, data, onChange, selected }) => {
    return (
        <div style={{ padding: '16px' }}>
            <h3 style={{ color: "#800000" }}>{title}</h3>
            <TreeView
                multiSelect
                onNodeSelect={(event, nodeIds) => onChange(nodeIds)}
                defaultCollapseIcon={<span>-</span>}
                defaultExpandIcon={<span>+</span>}
                selected={selected} // Ensure TreeView reflects selected nodes
                sx={{ height: 400, flexGrow: 1, overflowY: 'auto' }}
            >
                {renderTreeItems(data)}
            </TreeView>
        </div>
    );
};

const renderTreeItems = (nodes) => {
    if (!nodes) return null;

    return nodes.map((node) => (
        <TreeItem key={node.riviAvain} nodeId={node.riviAvain} label={node.nimi}>
            {renderTreeItems(node.children)}
        </TreeItem>
    ));
};


/* Filter Group Component */
const FilterGroup = ({ title, items, onChange, selectedItems = [] }) => {
    return (
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormLabel style={{ color: "#800000" }}>{title}</FormLabel>
            <FormGroup>
                {(items || []).map((d, i) => (
                    <FormControlLabel
                        key={d.riviAvain}
                        control={
                            <Checkbox
                                checked={selectedItems.includes(d.riviAvain)}
                                onChange={(e) => onChange(d.riviAvain, e.target.checked)}
                            />
                        }
                        label={d.nimi}
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
};

/* Main Component */
export class SkenaarioVertailu extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            projektit: [],
            organisaatiot: [],
            kustannuspaikka: [],
            selectedOrganisaatiot: [],
            selectedKustannuspaikka: [],
            selectedScenarios: ['skenario1', 'skenario2'],
            loading: true
        };
    }

    componentDidMount() {
        // Simulated API calls for project, organization, and cost center data
        authFetch(this.props.pca, API_ORGANISAATIOT_PREFIX + '/Read').then(response => response.json()).then(data => {
            this.setState({ organisaatiot: data || [] });
        });

        authFetch(this.props.pca, API_KUSTANNUSPAIKKA_PREFIX + '/Read').then(response => response.json()).then(data => {
            this.setState({ kustannuspaikka: data || [] });
        });

        this.setState({ loading: false });
    }

    handleFilterGroupChange = (name, ids) => {
        this.setState({ [name]: ids });
    };

    handleScenarioChange = (id, checked) => {
        this.setState((prevState) => {
            const selectedScenarios = checked
                ? [...prevState.selectedScenarios, id]
                : prevState.selectedScenarios.filter((scenario) => scenario !== id);
            return { selectedScenarios };
        });
    };


    render() {
        const { organisaatiot, kustannuspaikka, selectedScenarios } = this.state;

        return (
            <div style={{ padding: 10 }}>
                <div style={{ overflowY: "scroll", height: "90vh" }}>
                    {this.state.loading ? (
                        <CircularProgress />
                    ) : (
                        <Grid container spacing={1}>
                            <Grid item xs={9}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        {/* Päästöt and Kulutus Graafit */}
                                        <Paper>
                                            <ViivaGraafi
                                                title={"Päästöt - Kuukausittain"}
                                                barsLegend={"Päästöt (kgCo2)"}
                                                linesLegend={"Päästöt (kgCo2)"}
                                                data={{
                                                    sarja1: selectedScenarios.includes('skenario1') ? dummyData[0].paastot : [],
                                                    sarja2: selectedScenarios.includes('skenario2') ? dummyData[1].paastot : []
                                                }}
                                                lineSeries1={"sarja1"}
                                                lineSeries2={"sarja2"}
                                                timeScale={[2023, 2026]}
                                                width={800}
                                                height={300}
                                            />

                                            <ViivaGraafi
                                                title={"Kulutus - Kuukausittain"}
                                                barsLegend={"Kulutus (eur)"}
                                                linesLegend={"Kulutus (eur)"}
                                                data={{
                                                    sarja1: selectedScenarios.includes('skenario1') ? dummyData[0].kulutus : [],
                                                    sarja2: selectedScenarios.includes('skenario2') ? dummyData[1].kulutus : []
                                                }}
                                                lineSeries1={"sarja1"}
                                                lineSeries2={"sarja2"}
                                                timeScale={[2023, 2026]}
                                                width={800}
                                                height={300}
                                            />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={3} style={{ paddingTop: 16 }}>
                                <Paper>
                                    {/* Skenaariot */}
                                    <div style={{ padding: '16px' }}>
                                        <h3 style={{ color: "#800000" }}>Skenaariot</h3>
                                        <div>
                                            {['skenario1', 'skenario2'].map((id) => (
                                                <div key={id}>
                                                    <Checkbox
                                                        checked={selectedScenarios.includes(id)}
                                                        onChange={(e) => this.handleScenarioChange(id, e.target.checked)}
                                                    />
                                                    <label>{id.replace('skenario', 'Skenaario ')}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Organisaatiot TreeView */}
                                    <TreeViewComponent
                                        title="Organisaatiot"
                                        data={organisaatiotDummyData}
                                        selected={this.state.selectedOrganisaatiot}
                                        onChange={(ids) => this.handleFilterGroupChange("selectedOrganisaatiot", ids)}
                                    />

                                    {/* Kustannuspaikka TreeView */}
                                    <TreeViewComponent
                                        title="Kustannuspaikka"
                                        data={kustannuspaikkaDummyData}
                                        selected={this.state.selectedKustannuspaikka}
                                        onChange={(ids) => this.handleFilterGroupChange("selectedKustannuspaikka", ids)}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    )}
                </div>
            </div>
        );
    }
}